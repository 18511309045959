import { InboxProfileTypes } from '../constants/index';

const initialState = {
    loading: false,
    unreadRequestCount: null,
    unreadClaimCount: null,
    taskBodyDetails: null,
    userList: null,
    markStar: null,
    markRead: null,
    starredListItems: null,
    approveRejectTask: null,
    claimTask: null,
    claimList: null,
    archivedList: null,
    openMyRequestList: null,
    userOptions: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case InboxProfileTypes.get_unreadRequestCount.REQUEST:
            return {
                ...state,
                loading: true
            };
        case InboxProfileTypes.get_unreadRequestCount.SUCCESS:
            return {
                ...state,
                loading: false,
                unreadRequestCount: payload
            };
        case InboxProfileTypes.get_unreadRequestCount.FAILURE:
            return {
                ...state,
                loading: false
            };
        case InboxProfileTypes.get_unreadClaimCount.REQUEST:
            return {
                ...state,
                loading: true
            };
        case InboxProfileTypes.get_unreadClaimCount.SUCCESS:
            return {
                ...state,
                loading: false,
                unreadClaimCount: payload
            };
        case InboxProfileTypes.get_unreadClaimCount.FAILURE:
            return {
                ...state,
                loading: false
            };
        case InboxProfileTypes.get_taskBodyForApproval.REQUEST:
            return {
                ...state,
                loading: true
            };
        case InboxProfileTypes.get_taskBodyForApproval.SUCCESS:
            return {
                ...state,
                loading: false,
                taskBodyDetails: payload
            };
        case InboxProfileTypes.get_taskBodyForApproval.FAILURE:
            return {
                ...state,
                loading: false
            };
        case InboxProfileTypes.get_userList.REQUEST:
            return {
                ...state,
                loading: true
            };
        case InboxProfileTypes.get_userList.SUCCESS:
            return {
                ...state,
                loading: false,
                userList: payload
            };
        case InboxProfileTypes.get_userList.FAILURE:
            return {
                ...state,
                loading: false
            };
        case InboxProfileTypes.post_markStar.REQUEST:
            return {
                ...state,
                loading: true
            };
        case InboxProfileTypes.post_markStar.SUCCESS:
            return {
                ...state,
                loading: false,
                markStar: payload
            };
        case InboxProfileTypes.post_markStar.FAILURE:
            return {
                ...state,
                loading: false
            };
        case InboxProfileTypes.post_markRead.REQUEST:
            return {
                ...state,
                loading: true
            };
        case InboxProfileTypes.post_markRead.SUCCESS:
            return {
                ...state,
                loading: false,
                markRead: payload
            };
        case InboxProfileTypes.post_markRead.FAILURE:
            return {
                ...state,
                loading: false
            };
        case InboxProfileTypes.get_starredListItems.REQUEST:
            return {
                ...state,
                loading: true
            };
        case InboxProfileTypes.get_starredListItems.SUCCESS:
            return {
                ...state,
                loading: false,
                starredListItems: payload
            };
        case InboxProfileTypes.get_starredListItems.FAILURE:
            return {
                ...state,
                loading: false
            };
        case InboxProfileTypes.post_approveRejectTask.REQUEST:
            return {
                ...state,
                loading: true
            };
        case InboxProfileTypes.post_approveRejectTask.SUCCESS:
            return {
                ...state,
                loading: false,
                approveRejectTask: payload,
                taskBodyDetails: null
            };
        case InboxProfileTypes.post_approveRejectTask.FAILURE:
            return {
                ...state,
                loading: false
            };
        case InboxProfileTypes.post_claimTask.REQUEST:
            return {
                ...state,
                loading: true
            };
        case InboxProfileTypes.post_claimTask.SUCCESS:
            return {
                ...state,
                loading: false,
                claimTask: payload
            };
        case InboxProfileTypes.post_claimTask.FAILURE:
            return {
                ...state,
                loading: false
            };
        case InboxProfileTypes.get_claimList.REQUEST:
            return {
                ...state,
                loading: true
            };
        case InboxProfileTypes.get_claimList.SUCCESS:
            return {
                ...state,
                loading: false,
                claimList: payload
            };
        case InboxProfileTypes.get_claimList.FAILURE:
            return {
                ...state,
                loading: false
            };
        case InboxProfileTypes.get_archivedList.REQUEST:
            return {
                ...state,
                loading: true
            };
        case InboxProfileTypes.get_archivedList.SUCCESS:
            return {
                ...state,
                loading: false,
                archivedList: payload
            };
        case InboxProfileTypes.get_archivedList.FAILURE:
            return {
                ...state,
                loading: false
            };
        case InboxProfileTypes.get_openMyRequestList.REQUEST:
            return {
                ...state,
                loading: true
            };
        case InboxProfileTypes.get_openMyRequestList.SUCCESS:
            return {
                ...state,
                loading: false,
                openMyRequestList: payload
            };
        case InboxProfileTypes.get_openMyRequestList.FAILURE:
            return {
                ...state,
                loading: false
            };
            case InboxProfileTypes.get_userForInboxSearch.REQUEST:
                return {
                    ...state,
                    loading: true
                };
            case InboxProfileTypes.get_userForInboxSearch.SUCCESS:
                return {
                    ...state,
                    loading: false,
                    userOptions: payload
                };
            case InboxProfileTypes.get_userForInboxSearch.FAILURE:
                return {
                    ...state,
                    loading: false
                };
        default:
            return state;
    }
};
