import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
} from '../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus
};

export const POST_CHANGE_PASSWORD_API = `${API_URL}/authsrvc/auth/changePassword`;
export const GET_USER_SECRET_QUESTION_API = `${API_URL}/mfasrvc/SecretQuestion/findSecretQuestionForUser`;
export const POST_RESET_USER_ALL_SEC_QUES_API = `${API_URL}/mfasrvc/SecretQuestion/resetAllSecretQuestionsForUser`;
export const GET_MFA_CONFIG_REGISTERED_FACTORS_API = `${API_URL}/mfasrvc/mfaConfig/registeredFactors`;
export const DELETE_REGSITERED_TOTP_CONFIG_API = `${API_URL}/mfasrvc/totpAuthentication/delete`;
export const DELETE_REGSITERED_GOOGLE_AUTH_API = `${API_URL}/mfasrvc/googleAuthentication/delete`;
export const DELETE_REGSITERED_PUSH_AUTH_API = `${API_URL}/mfasrvc/pushAuthentication/delete`;
export const DELETE_REGSITERED_SEC_QUES_AUTH_API = `${API_URL}/mfasrvc/SecretQuestion/resetAllSecretQuestionsForUser`;
export const POST_UPLOAD_IMAGE_API = `${API_URL}/selfservice/api/selfservice/userprofile`;
export const DELETE_USER_IMAGE_API = `${API_URL}/selfservice/api/selfservice/userprofile`;
export const GET_ADDITIONAL_MFA = `${API_URL}/mfasrvc/mfaConfig/additionalMfa`;
export const GET_DEVICE_REGISTRATION_QR_API = `${API_URL}/authsrvc/mobile/auth/getQRCode`;
export const GET_REGISTERED_DEVICES_API = `${API_URL}/authsrvc/mobile/auth/devices`;
export const DELETE_REGISTERED_DEVICE_API = `${API_URL}/authsrvc/mobile/auth/removeDevice`;

export const SettingsActionTypes = {
    post_changePassword : createRequestActionTypes("POST_CHANGE_PASSWORD"),
    get_userSecretQuestion : createRequestActionTypes("GET_USER_SECRET_QUESTION"),
    post_resetUserAllSecQues : createRequestActionTypes("POST_RESET_USER_ALL_SEC_QUES"),
    clear_userSecretQuestion : createRequestActionTypes("CLEAR_USER_SECRET_QUESTION"),
    get_mfaConfigRegisteredFactors : createRequestActionTypes("GET_MFA_CONFIG_REGISTERED_FACTORS"),
    del_registeredTotpConfig : createRequestActionTypes("DELETE_REGSITERED_TOTP_CONFIG"),
    del_registeredGoogleAuth : createRequestActionTypes("DELETE_REGSITERED_GOOGLE_AUTH"),
    del_registeredPushAuth : createRequestActionTypes("DELETE_REGSITERED_PUSH_AUTH"),
    del_registeredSecQues : createRequestActionTypes("DELETE_REGSITERED_SEC_QUES_AUTH"),
    post_uploadImage : createRequestActionTypes("POST_UPLOAD_IMAGE"),
    del_userImage : createRequestActionTypes("DEL_USER_IMAGE"),
    get_additionalMfa:createRequestActionTypes("GET_ADDITIONAL_MFA"),
    get_deviceRegistrationQR: createRequestActionTypes("GET_DEVICE_REGISTRATION_QR"),
    clear_deviceRegistrationQR: createRequestActionTypes("CLEAR_GET_DEVICE_REGISTRATION_QR"),
    get_registeredDevices: createRequestActionTypes("GET_REGISTERED_DEVICES"),
    clear_registeredDevices: createRequestActionTypes("CLEAR_GET_REGISTERED_DEVICES"),
    del_registeredDevice: createRequestActionTypes("DELETE_REGISTERED_DEVICE"),
    clear_registeredDevice: createRequestActionTypes("CLEAR_DELETE_REGISTERED_DEVICE"),

};