const themePalette = {
    "theme" : {
        "defalutTheme" : {
            "primaryColor" : "#363793"
        },
        "customTheme" : {
            "primaryColor" : "#172A54"
        },
        "MHDTheme":{
            "primaryColor":"#e41937"
        }
    }
}

export default themePalette