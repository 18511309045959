import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import snackbarReducer from '../shared/Notifier/snackbarReducer';
import userProfileReducer from '../Views/Profile/reducers/userProfileReducer';
import inboxReducer from '../Views/Inbox/reducers/inboxReducer';
import applicationReducer from '../Views/Application/reducers/applicationReducer';
import settingsReducer from '../Views/Settings/reducers/settingsReducer';
import welcomeReducer from '../Views/Welcome/reducers/welcomeReducer';
import campaignReducer from '../Views/Campaign/reducer/CampaignReducer';
import authReducer from '../Views/Authenticantion/authReducer';
import communicationReducer from '../Views/Communication/reducers/communicationReducer';

export default combineReducers({
    form: formReducer,
    auth: authReducer,
    userProfileReducer: userProfileReducer,
    snackbarReducer: snackbarReducer,
    inboxReducer: inboxReducer,
    applicationReducer: applicationReducer,
    settingsReducer: settingsReducer,
    welcomeReducer: welcomeReducer,
    campaignReducer: campaignReducer,
    communicationReducer: communicationReducer
})