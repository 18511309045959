export const errors = [
  // AUTH Module
  {
    key: 'REGSRVC.UNKNOWN',
    value: 'Error. Please try again later or contact Cymmetri Administrator.'
  },
  {
    key: 'REGSRVC.USER_NOT_FOUND',
    value: 'User not found.'
  },
  {
    key: 'REGSRVC.INVALID_ARGUMENTS',
    value: 'Error. Please correct input and try again.'
  },
  {
    key: 'AUTHSRVC.ACCESS_DENIED_TOKEN',
    value: 'Session expired. Please login again'
  },
  {
    key: 'REGSRVC.INVALID_DOMAIN',
    value: 'Invalid Domain. Please try again.'
  },
  {
    key: 'REGSRVC.INVALID_CREDENTIALS',
    value: 'Invalid Credentials. Please try again.'
  },
  {
    key: 'REGSRVC.TERMS_AND_CONDITIONS_NOT_FOUND	',
    value: 'Invalid Terms & Conditions. Please try again.'
  },
  {
    key: 'REGSRVC.INVALID_ACCOUNT_VERIFICATION_TOKEN',
    value: 'Invalid request. Contact Cymmetri administrator.'
  },
  {
    key: 'REGSRVC.DATA_IS_NOT_VALID',
    value: 'Invalid data. Please try again.'
  },
  {
    key: 'REGSRVC.PASSWORD_NOT_VALID',
    value: 'Invalid password. Please try again'
  },
  {
    key: 'REGSRVC.EMAIL_EXISTS',
    value: 'Duplicate Email Address. Please try again.'
  },
  {
    key: 'REGSRVC.DOMAIN_EXISTS',
    value: 'Duplicate Domain.'
  },
  {
    key: 'REGSRVC.DB_CONFIG_EXISTS',
    value: 'Database already exists. Contact Cymmetri administrator.'
  },
  {
    key: 'REGSRVC.USER_ALREADY_ACTIVE',
    value: 'User status is active. Contact Cymmetri administrator.'
  },
  // USER MODULE
  {
    key: 'USRSRVC.UNKNOWN',
    value: 'Error. Please try again later or contact Cymmetri Administrator.'
  },
  {
    key: 'USRSRVC.INVALID_EXTENSION_ENDDATE',
    value: 'Extended end date should be less than current access end date.'
  },
  {
    key: 'USRSRVC.INVALID_ARGUMENTS',
    value: 'Error. Please correct input and try again.'
  },
  {
    key: 'USRSRVC.NONUNIQUE_GROUPNAME',
    value: 'Group name already exists. Please try again.'
  },
  {
    key: 'USRSRVC.GROUPTYPE_NOT_FOUND',
    value: 'Group type not found. Contact Cymmetri administrator.'
  },
  {
    key: 'USRSRVC.OU_NOT_FOUND',
    value: 'Organization Unit not found. Please try again.'
  },
  {
    key: 'USRSRVC.PARENTGROUP_NOT_FOUND',
    value: 'Parent Group not found. Please try again.'
  },
  {
    key: 'USRSRVC.GROUP_NOT_FOUND',
    value: 'Group not found. Please try again.'
  },
  {
    key: 'USRSRVC.USER_NOT_FOUND',
    value: 'User not found. Please try again.'
  },
  {
    key: 'USRSRVC.CYCLIC_UPDATE',
    value: 'Operation not allowed for current input.'
  },
  {
    key: 'USRSRVC.INHERITED_GROUP',
    value: 'Operation not allowed for current input.'
  },
  {
    key: 'USRSRVC.USERTYPE_NOT_FOUND',
    value: 'User type not found. Please try again.'
  },
  {
    key: 'USRSRVC.EXISTING_MOBILE',
    value: 'User mobile number in use. Please try again.'
  },
  {
    key: 'USRSRVC.EXISTING_EMAIL',
    value: 'User email address in use. Please try again.'
  },
  {
    key: 'USRSRVC.DEPARTMENT_NOT_FOUND',
    value: 'Department not found. Please try again.'
  },
  {
    key: 'USRSRVC.DESIGNATION_NOT_FOUND',
    value: 'Designation not found. Please try again.'
  },
  {
    key: 'USRSRVC.COUNTRY_NOT_FOUND',
    value: 'Country not found. Please try again.'
  },
  {
    key: 'USRSRVC.EXISTING_LOGIN',
    value: 'User Login ID in use. Please try again.'
  },
  {
    key: 'USRSRVC.APPLICATION_NOT_FOUND',
    value: 'Application not found. Please try again.'
  },
  {
    key: 'USRSRVC.APPLICATION_ROLE_NOT_FOUND',
    value: 'Application role not found. Please try again.'
  },
  {
    key: 'USRSRVC.USER_NOT_PROVISIONED',
    value: 'User not provisioned. Please try again.'
  },
  {
    key: 'USRSRVC.CHILD_GROUP_FOUND',
    value: 'Cannot Delete as Child group found.'
  },
  {
    key: 'USRSRVC.GROUP_HAS_ASSIGNED_APPS',
    value: 'Group has assigned applications. Remove and try again.'
  },
  {
    key: 'USRSRVC.USER_ASSIGNED_GROUP',
    value: 'Cannot delete as User assigned to group.'
  },
  // MFA MODULE
  {
    key: 'MFASRVC.UNKNOWN',
    value: 'Error. Please try again.'
  },
  {
    key: 'MFASRVC.USER_NOT_FOUND',
    value: 'User not found. Please try again.'
  },
  {
    key: 'MFASRVC.ALREADY_SENT_SMS_OTP',
    value: 'SMS OTP already sent.'
  },
  {
    key: 'MFASRVC.INVALID_SMS_OTP',
    value: 'Invalid SMS OTP provided.'
  },
  {
    key: 'MFASRVC.MFA_CONFIG_NOT_FOUND',
    value: 'Multi Factor Authentication configuration not found.'
  },
  {
    key: 'MFASRVC.INVALID_ARGUMENTS',
    value: 'Error. Invalid request.'
  },
  {
    key: 'MFASRVC.QUESTION_NOT_FOUND',
    value: 'Question not found.'
  },
  {
    key: 'MFASRVC.DUPLICATE_QUESTION',
    value: 'Question field is duplicate. Please try again.'
  },
  {
    key: 'MFASRVC.INCORRECT_ANSWER',
    value: 'Answer field is incorrect. Please try again.'
  },
  {
    key: 'MFASRVC.INVALID_USERID',
    value: 'Invalid User. Please try again.'
  },
  {
    key: 'MFASRVC.INVALID_QUESTIONID',
    value: 'Question is invalid. Please try again.'
  },
  {
    key: 'MFASRVC.INVALID_ANSWER',
    value: 'Answer field is invalid. Please try again.'
  },
  {
    key: 'MFASRVC.NON_REMOVABLE_QUESTION',
    value: 'Question in use and cannot be removed.'
  },
  {
    key: 'MFASRVC.QUESTION_NOT_REGISTERED',
    value: 'Question is not registered.'
  },
  {
    key: 'MFASRVC.USER_NOT_REGISTERED',
    value: 'User is not registered for TOTP/Push Authentication'
  },
  {
    key: 'MFASRVC.USER_RESPONSE_PENDING',
    value: 'User response is pending.'
  },
  {
    key: 'MFASRVC.EMPTY_QUESTION',
    value: 'Question field is empty. Please try again.'
  },
  {
    key: 'MFASRVC.FAILED_MINIMUM_CORRECT_ANSWER',
    value: 'Please provide correct answer for each question.'
  },
  {
    key: 'MFASRVC.INVALID_TOTP',
    value: 'Invalid Time based OTP provided.'
  },
  {
    key: 'MFASRVC.USER_DENIED_ACCESS',
    value: 'User denied access.'
  },
  // {
  //   key: 'USRSRVC.MISSING_DATES',
  //   value: 'Please select new end date'
  // },
  {
    key: 'MFASRVC.SMS_OTP_EXPIRED',
    value: 'SMS OTP expired.'
  },
  {
    key: 'MFASRVC.SHORT_ANSWER_LENGTH',
    value: 'Answer length is short.'
  },
  //Push Auth
  {
    key: 'MFASRVC.DEVICE_INFO_NOT_FOUND',
    value: 'Please scan the QR code.'
  },
  // AUTHSRVC
  {
    key: 'AUTHSRVC.UNKNOWN',
    value: 'Please contact system administrator.'
  },
  {
    key: 'AUTHSRVC.INVALID_ARGUMENTS',
    value: 'Invalid argument.'
  },
  {
    key: 'AUTHSRVC.ACCESS_DENIED',
    value: 'Invalid Credentials.'
  },
  {
    key: 'AUTHSRVC.INVALID_TOKEN',
    value: 'Token is invalid.'
  },
  {
    key: 'AUTHSRVC.USER_LOCKED',
    value: 'User locked.'
  },
  {
    key: 'AUTHSRVC.USER_NOT_FOUND',
    value: 'User not found.'
  },
  {
    key: 'AUTHSRVC.EMAIL_NOT_FOUND',
    value: 'Email not found.'
  },
  {
    key: 'AUTHSRVC.PASSWORD_COMPOSITION_RULE_VIOLATION',
    value: 'Password provided does not match the required guidelines.'
  },
  {
    key: 'AUTHSRVC.INVALID_USER_ACCOUNT_STATE',
    value: 'Your account is expired/inactive. Please contact Cymmetri Administrator'
  },
  {
    key: 'AUTHSRVC.MOBILE_NOT_FOUND',
    value: 'Mobile number is not registered, please contact Cymmetri Administrator'
  },
  {
    key: 'AUTHSRVC.INVALID_DATE',
    value: 'Error. Invalid Date.'
  },
  {
    key: 'AUTHSRVC.DELEGATE_CONSENT_NOT_FOUND',
    value: 'Error. Delegate consent not found.'
  },
  {
    key: 'AUTHSRVC.DELEGATE_USER_NOT_FOUND',
    value: 'Error. User doesn\'t have delegation access.'
  },
  {
    key: 'AUTHSRVC.NO_OPTION_AVAILABLE',
    value: 'Please contact Cymmetri Administrator for Password Reset.'
  },
  {
    key: 'AUTHSRVC.DELEGATION_DEACTIVATE',
    value: 'Error. Delegation is inactive.'
  },
  {
    key: 'AUTHSRVC.PASSWORD_EXPIRED',
    value: 'Your password has expired, Please reset your password.'
  },
  // WorkFlow
  {
    key: 'WKFLSRVC.WORKFLOW_IN_PROGESS',
    value: 'Request is pending for approval.'
  },
  {
    key: 'WKFLSRVC.UNKNOWN',
    value: 'Please contact system Administrator'
  },
  {
    key: 'WKFLSRVC.WORKFLOW_NOT_FOUND',
    value: 'No workflow avaliable'
  },
  {
    key: 'WKFLSRVC.INVALID_ARGUMENTS',
    value: 'Please check input and try again.'
  },
  {
    key: 'WKFLSRVC.INVALID_LEVEL',
    value: 'Worklow Config issue'
  },
  {
    key: 'WKFLSRVC.EXCEEDED_REPORTING_MANAGER',
    value: 'Can not more than reporting manager'
  },
  {
    key: 'WKFLSRVC.WORKFLOW_SETUP_NOT_FOUND',
    value: 'No workflow config avaliable'
  },
  {
    key: 'WKFLSRVC.REQUESTOR_NOT_FOUND',
    value: 'Requestor not found in the system.'
  },
  {
    key: 'WKFLSRVC.REPORTING_MANAGER_NOT_FOUND',
    value: "Please assign approver's manager to complete workflow."
  },
  {
    key: 'USRSRVC.LAST_DATE_REACHED',
    value: "Application's request end date is greater than the user's end date."
  },
  {
    key: 'USRSRVC.MISSING_DATES',
    value: 'Incorrect dates, Please ensure selected date range should be proper.'
  },
  {
    key: 'USRSRVC.EXPIRED_TIME_BOUND',
    value: 'Access request duration has ended'
  },
  //Governance
  {
    key: 'IGSRVC.UNKNOWN',
    value: 'Error. Please try again.'
  },
  {
    key: 'IGSRVC.INVALID_JWT',
    value: 'Error. Invalid JWT token.'
  },
  {
    key: 'IGSRVC.CAMPAIGN_COMPLETION_PERIOD_EXCEED',
    value: 'Error. Campaign Completion Period Exceeded.'
  },
  {
    key: 'IGSRVC.CAMPAIGN_STAGE_NOT_FOUND',
    value: 'Error. Campaign Stage Not Found.'
  },
  {
    key: 'IGSRVC.CAMPAIGN_SCOPE_NOT_FOUND',
    value: 'Error. Campaign Scope Not Found.'
  },
  {
    key: 'IGSRVC.CAMPAIGN_ALREADY_IN_DRAFT_STATE',
    value: 'Error. Campaign Already In Draft State.'
  },
  {
    key: 'IGSRVC.CAMPAIGN_ALREADY_IN_PUBLISHED_STATE',
    value: 'Error. Campaign Already In Published State.'
  },
  {
    key: 'IGSRVC.CAMPAIGN_EXECUTION_IN_PROGRESS',
    value: 'Error. Campaign Execution in Progress.'
  },
  {
    key: 'IGSRVC.CAMPAIGN_ASSIGNMENT_NOT_FOUND',
    value: 'Error. Campaign Assignment Not Found.'
  },
  {
    key: 'IGSRVC.CAMPAIGN_HISTORY_NOT_FOUND',
    value: 'Error. Campaign History Not Found.'
  },
  {
    key: 'IGSRVC.UNABLE_TO_PROCESS_RESPONSE',
    value: 'Error. Unable To Process Response.'
  },
  {
    key: 'IGSRVC.CAMPAIGN_ASSIGNMENT_APPLICATION_NOT_FOUND',
    value: 'Error. Campaign Assignment Application Not Found.'
  },
  {
    key: 'IGSRVC.CAMPAIGN_ASSIGNMENT_APPLICATION_ROLE_NOT_FOUND',
    value: 'Error. Campaign Assignment Application Role Not Found.'
  },
  {
    key: 'IGSRVC.APP_ROLE_ALREADY_PROCEED',
    value: 'Error. App Role Already Proceeded.'
  },
  {
    key: 'IGSRVC.INACTIVE_USER_FOUND',
    value: 'Error. Inactive User Found.'
  },
  {
    key: 'IGSRVC.NO_ACTIVE_EXECUTION_FOUND',
    value: 'Error. No Active Execution Found.'
  },
  {
    key: 'IGSRVC.INVALID_CRON_EXPRESSION',
    value: 'Error. Invalid Cron Expression.'
  },
  {
    key: 'IGSRVC.DUPLICATE_CAMPAIGNNAME',
    value: 'Error. Duplicate Campaign Name.'
  },
  {
    key: 'IGSRVC.CAMPAIGN_NOT_FOUND',
    value: 'Campaign not found.'
  },
  {
    key: 'IGSRVC.INVALID_ARGUMENTS',
    value: 'Error. Please correct input and try again.'
  },
  {
    key: 'IGSRVC.CAMPAIGN_STATE_STARTED',
    value: 'Error. Campaign State Already Started.'
  },
  {
    key: 'IGSRVC.STAGE_LIMIT_EXCEED',
    value: 'Error. Stage Limit Exceeded.'
  },
  {
    key: 'IGSRVC.DUPLICATE_STAGE',
    value: 'Error. Duplicate Stage.'
  },
  {
    key: 'IGSRVC.ASSIGNMENT_ALREADY_PROCEED',
    value: 'Error. Assignment Already Proceeded.'
  },
  // IGPROCESS
  {
    key: 'IGPROCESS.UNKNOWN',
    value: 'Error. Please try again.'
  },
  {
    key: 'IGPROCESS.INVALID_ARGUMENTS',
    value: 'Error. Please correct input and try again.'
  },
  {
    key: 'IGPROCESS.CAMPAIGN_NOT_FOUND',
    value: 'Error. Campaign Not Found.'
  },
  {
    key: 'IGPROCESS.NO_ACTIVE_EXECUTION_FOUND',
    value: 'Error. No Active Execution Found.'
  },
  {
    key: 'IGPROCESS.CAMPAIGN_HISTORY_NOT_FOUND',
    value: 'Error. Campaign History Not Found.'
  },
  {
    key: 'IGPROCESS.INVALID_CAMPAIGN_ITERATION',
    value: 'Error. Invalid Campaign Iteration.'
  },
  {
    key: 'IGPROCESS.CAMPAIGN_EXECUTION_IN_PROGRESS',
    value: 'Error. Campaign Execution In Progress.'
  },
  {
    key: 'IGPROCESS.MATCHING_ASSIGNMENTS_NOT_FOUND',
    value: 'Error. Matching Assignments Not Found.'
  },
  // SCHEDULER
  {
    key: 'SCHEDULER.UNKNOWN',
    value: 'Error. Please try again.'
  },
  {
    key: 'SCHEDULER.TASK_NOT_FOUND',
    value: 'Error. Task Not Found.'
  },
  {
    key: 'SCHEDULER.TASK_NOT_ACTIVE',
    value: 'Error. Task Not Active.'
  },
  {
    key: 'SCHEDULER.INVALID_ARGUMENTS',
    value: 'Error. Please correct input and try again.'
  },
  {
    key: 'SCHEDULER.INVALID_START_DATE',
    value: 'Error. Invalid Start Date.'
  },
  {
    key: 'SCHEDULER.TENANT_NOT_FOUND',
    value: 'Error. Tenant Not Found.'
  },
  {
    key: 'SCHEDULER.UPDATE_NOT_SUPPORTED',
    value: 'Error. Update Is Not Supported.'
  },
  {
    key: 'SCHEDULER.CRON_REPETITION_BELOW_ALLOWED_LIMIT',
    value: 'Error. Cron Repetition Is Below Allowed Limit.'
  },
  // RULESRVC
  {
    key: 'RULESRVC.DEFAULT_RULE_NOT_FOUND',
    value: 'Default rule not found.'
  },
  // SAMLEXTIDPCONFIGSRVC
  {
    key: 'SAMLEXTIDPCONFIGSRVC.INVALID_ARGUMENTS',
    value: 'Please correct the input and try again.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.CERTIFICATE_PARSING_ERROR',
    value: 'Error occured while parsing certificate.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.AUTH_TYPE_CANNOT_BE_UPDATED',
    value: 'Identity provider type cannot be updated.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.CONNECTION_FAILED',
    value: 'Please check your internet connection.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.IDP_CONFIGURATION_NOT_FOUND',
    value: 'Identity provider configuration not found.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.MULTIPLE_IDP_CONFIGURATION_FOUND',
    value: 'Multiple identity provider configuration found.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.NAME_ID_POLICY_NAME_ID_VALUE_MISMATCH',
    value: 'NameIdPolicy and NameIdValue does not match.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.SP_CONFIGURATION_NOT_FOUND',
    value: 'Service provider configuration not found.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.UNAUTHORIZED',
    value: 'Please contact system administrator.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.SERVICE_PROVIDER_NOT_FOUND',
    value: 'Service provider not found.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.CERTIFICATE_NOT_FOUND',
    value: 'Certificate not found.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.EAMIL_EXISTS',
    value: 'Email already exists.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.CUSTOM_IDENTITY_TYPE_MUST_HAVE_ID',
    value: 'Custom identity type must have ID.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.INACTIVE_CONFIGURATION_FOUND',
    value: 'Inactive configuration found.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.INVALID_IDP_CONFIGURED',
    value: 'Invalid IDP configured.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.NO_MAPPING_FOUND',
    value: 'No mapping found.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.INVALID_POLICY_MAPPING',
    value: 'Invalid policy mapping.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.POLICY_MAP_REQUIRED_FIELD_NOT_FOUND',
    value: 'Policy map required field not found.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.MANDATORY_FIELD_EXCEPTION',
    value: 'Mandatory field exception.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.MAPPING_ALREADY_EXISTS',
    value: 'Mapping already exists.'
  },
  {
    key: 'SAMLEXTIDPCONFIGSRVC.JIT_CONFIGURATION_NOT_FOUND',
    value: 'JIT configuration not found.'
  },
  {
    key: 'PROVSRVC.UNSUPPORTED_DELEGETE_MFA_SETUP',
    value: 'Delegatee cant setup mfa for application having additional authentication',
  },
  {
    key: 'MFASRVC.PUSH_NOTIFICATION_FAILED',
    value: 'Failed to send the push notification. Please try again later or contact Cymmetri Administrator. '
  },
  // SLFSRVC.
  {
    key: 'SLFSRVC.EXISITNG_APP_IN_TAG_FOUND',
    value: 'Application already available in tag.'
  },
  {
    key: 'AD-ADAPTER.FAILED_TO_PWD_CHANGE',
    value: 'Password change failed.',
  },
  {
    key: "AUTHSRVC.INVALID_CONFIG",
    value: "Invalid config for authentication policy or rule",
  },
  {
    key: 'PROVSRVC.UNAUTHORIZED',
    value: 'Unauthorized access.',
  },
  {
    key: 'MFASRVC.INVALID_MFA_OTP_CONFIG',
    value: 'Invalid Otp config, please contact admin',
  },
  {
    key: 'MFASRVC.EMAIL_NOT_FOUND',
    value: 'Email not registered, please contact admin',
  },
  {
    key: 'MFASRVC.MOBILE_NOT_FOUND',
    value: 'Mobile not registered, please contact admin',
  },
  {
    key: 'MFASRVC.EMAIL_MOBILE_NOT_FOUND',
    value: 'Mobile or email not registered, please contact admin'
  },
  {
    key: "AUTHSRVC.LDAP_ACCESS_DENIED",
    value: "Access denied.",
  },
  {
    key: "REPORT.EMAIL_NOT_EXISTS_EXCEPTION",
    value: "User does not have registered email address.",
  },
]

export const getErrorMessage = (code) => {
  let message = errors.find(error => error.key === code);
  return message.value ? message.value : console.log('Not found');
}