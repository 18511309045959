import { CommunicationActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    corporateUpdatesData: [],
    bitsInformationData: [],
    hrUpdatesData: [],
    activeOrgVideo: [],
    activeAnnouncements: [],
    activeNewsletter: [],
    businessExcellenceSubject: [],
    descriptionbySubject: []
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CommunicationActionTypes.get_mailsByCategoryCorporate.REQUEST:
            return {
                ...state,
                loading: true
            };
        case CommunicationActionTypes.get_mailsByCategoryCorporate.SUCCESS:
            return {
                ...state,
                loading: false,
                corporateUpdatesData: payload
            };
        case CommunicationActionTypes.get_mailsByCategoryCorporate.FAILURE:
            return {
                ...state,
                loading: false
            };
        case CommunicationActionTypes.get_mailsByCategoryBits.REQUEST:
            return {
                ...state,
                loading: true
            };
        case CommunicationActionTypes.get_mailsByCategoryBits.SUCCESS:
            return {
                ...state,
                loading: false,
                bitsInformationData: payload
            };
        case CommunicationActionTypes.get_mailsByCategoryBits.FAILURE:
            return {
                ...state,
                loading: false
            };
        case CommunicationActionTypes.get_mailsByCategoryHr.REQUEST:
            return {
                ...state,
                loading: true
            };
        case CommunicationActionTypes.get_mailsByCategoryHr.SUCCESS:
            return {
                ...state,
                loading: false,
                hrUpdatesData: payload
            };
        case CommunicationActionTypes.get_mailsByCategoryHr.FAILURE:
            return {
                ...state,
                loading: false
            };
        case CommunicationActionTypes.get_active_public_OrgVideo.REQUEST:
            return {
                ...state,
                loading: true
            };
        case CommunicationActionTypes.get_active_public_OrgVideo.SUCCESS:
            return {
                ...state,
                loading: false,
                activeOrgVideo: payload
            };
        case CommunicationActionTypes.get_active_public_OrgVideo.FAILURE:
            return {
                ...state,
                loading: false
            };
        case CommunicationActionTypes.get_active_private_OrgVideo.REQUEST:
            return {
                ...state,
                loading: true
            };
        case CommunicationActionTypes.get_active_private_OrgVideo.SUCCESS:
            return {
                ...state,
                loading: false,
                activeOrgVideo: payload
            };
        case CommunicationActionTypes.get_active_private_OrgVideo.FAILURE:
            return {
                ...state,
                loading: false
            };
        case CommunicationActionTypes.get_activePublicAnnouncements.REQUEST:
            return {
                ...state,
                loading: true
            };
        case CommunicationActionTypes.get_activePublicAnnouncements.SUCCESS:
            return {
                ...state,
                loading: false,
                activeAnnouncements: payload
            };
        case CommunicationActionTypes.get_activePublicAnnouncements.FAILURE:
            return {
                ...state,
                loading: false
            };
        case CommunicationActionTypes.get_activePrivateAnnouncements.REQUEST:
            return {
                ...state,
                loading: true
            };
        case CommunicationActionTypes.get_activePrivateAnnouncements.SUCCESS:
            return {
                ...state,
                loading: false,
                activeAnnouncements: payload
            };
        case CommunicationActionTypes.get_activePrivateAnnouncements.FAILURE:
            return {
                ...state,
                loading: false
            };
        case CommunicationActionTypes.get_activeNewsLetter.REQUEST:
            return {
                ...state,
                loading: true
            };
        case CommunicationActionTypes.get_activeNewsLetter.SUCCESS:
            return {
                ...state,
                loading: false,
                activeNewsletter: payload
            };
        case CommunicationActionTypes.get_activeNewsLetter.FAILURE:
            return {
                ...state,
                loading: false
            };
        case CommunicationActionTypes.get_businessExcellenceSubject.REQUEST:
            return {
                ...state,
                loading: true
            };
        case CommunicationActionTypes.get_businessExcellenceSubject.SUCCESS:
            return {
                ...state,
                loading: false,
                businessExcellenceSubject: payload
            };
        case CommunicationActionTypes.get_businessExcellenceSubject.FAILURE:
            return {
                ...state,
                loading: false
            };
        case CommunicationActionTypes.get_descriptionbySubject.REQUEST:
            return {
                ...state,
                loading: true
            };
        case CommunicationActionTypes.get_descriptionbySubject.SUCCESS:
            return {
                ...state,
                loading: false,
                descriptionbySubject: payload
            };
        case CommunicationActionTypes.get_descriptionbySubject.FAILURE:
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
};
