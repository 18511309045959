import { SettingsActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    changePassword: null,
    userSecretQuestion: null,
    resetUserAllSecQues: null,
    mfaConfigRegisteredFactors: null,
    deletedTotpConfig: null,
    deletedGoogleAuth: null,
    deletedPushAuth: null,
    deletedSecQues: null,
    uploadImage: null,
    delUserImage: null,
    additionalMfa:null,
    deviceRegistrationQR: null,
    registeredDevices: null,
    deleteRegisteredDevice: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SettingsActionTypes.post_changePassword.REQUEST:
            return {
                ...state,
                loading: true
            };
        case SettingsActionTypes.post_changePassword.SUCCESS:
            return {
                ...state,
                loading: false,
                changePassword: payload
            };
        case SettingsActionTypes.post_changePassword.FAILURE:
            return {
                ...state,
                loading: false
            };
        case SettingsActionTypes.get_userSecretQuestion.REQUEST:
            return {
                ...state,
                loading: true
            };
        case SettingsActionTypes.get_userSecretQuestion.SUCCESS:
            return {
                ...state,
                loading: false,
                userSecretQuestion: payload
            };
        case SettingsActionTypes.get_userSecretQuestion.FAILURE:
            return {
                ...state,
                loading: false
            };
        case SettingsActionTypes.post_resetUserAllSecQues.REQUEST:
            return {
                ...state,
                loading: true
            };
        case SettingsActionTypes.post_resetUserAllSecQues.SUCCESS:
            return {
                ...state,
                loading: false,
                resetUserAllSecQues: payload
            };
        case SettingsActionTypes.post_resetUserAllSecQues.FAILURE:
            return {
                ...state,
                loading: false
            };
        case SettingsActionTypes.clear_userSecretQuestion.SUCCESS:
            return {
                ...state,
                loading: false,
                userSecretQuestion: null
            };
        case SettingsActionTypes.get_mfaConfigRegisteredFactors.REQUEST:
            return {
                ...state,
                loading: true
            };
        case SettingsActionTypes.get_mfaConfigRegisteredFactors.SUCCESS:
            return {
                ...state,
                loading: false,
                mfaConfigRegisteredFactors: payload
            };
        case SettingsActionTypes.get_mfaConfigRegisteredFactors.FAILURE:
            return {
                ...state,
                loading: false
            };
        case SettingsActionTypes.del_registeredTotpConfig.REQUEST:
            return {
                ...state,
                loading: true
            };
        case SettingsActionTypes.del_registeredTotpConfig.SUCCESS:
            return {
                ...state,
                loading: false,
                deletedTotpConfig: payload
            };
        case SettingsActionTypes.del_registeredTotpConfig.FAILURE:
            return {
                ...state,
                loading: false
            };
        case SettingsActionTypes.del_registeredGoogleAuth.REQUEST:
            return {
                ...state,
                loading: true
            };
        case SettingsActionTypes.del_registeredGoogleAuth.SUCCESS:
            return {
                ...state,
                loading: false,
                deletedGoogleAuth: payload
            };
        case SettingsActionTypes.del_registeredGoogleAuth.FAILURE:
            return {
                ...state,
                loading: false
            };
        case SettingsActionTypes.del_registeredPushAuth.REQUEST:
            return {
                ...state,
                loading: true
            };
        case SettingsActionTypes.del_registeredPushAuth.SUCCESS:
            return {
                ...state,
                loading: false,
                deletedPushAuth: payload
            };
        case SettingsActionTypes.del_registeredPushAuth.FAILURE:
            return {
                ...state,
                loading: false
            };
        case SettingsActionTypes.del_registeredSecQues.REQUEST:
            return {
                ...state,
                loading: true
            };
        case SettingsActionTypes.del_registeredSecQues.SUCCESS:
            return {
                ...state,
                loading: false,
                deletedSecQues: payload
            };
        case SettingsActionTypes.del_registeredSecQues.FAILURE:
            return {
                ...state,
                loading: false
            };
        case SettingsActionTypes.post_uploadImage.REQUEST:
            return {
                ...state,
                loading: true
            };
        case SettingsActionTypes.post_uploadImage.SUCCESS:
            return {
                ...state,
                loading: false,
                uploadImage: payload
            };
        case SettingsActionTypes.post_uploadImage.FAILURE:
            return {
                ...state,
                loading: false
            };
        case SettingsActionTypes.del_userImage.REQUEST:
            return {
                ...state,
                loading: true
            };
        case SettingsActionTypes.del_userImage.SUCCESS:
            return {
                ...state,
                loading: false,
                delUserImage: payload
            };
        case SettingsActionTypes.del_userImage.FAILURE:
            return {
                ...state,
                loading: false
            };
        case SettingsActionTypes.get_additionalMfa.REQUEST:
            return {
                ...state,
                loading: true
            };
        case SettingsActionTypes.get_additionalMfa.SUCCESS:
            return {
                ...state,
                loading: false,
                additionalMfa: payload
            };
        case SettingsActionTypes.get_additionalMfa.FAILURE:
            return {
                ...state,
                loading: false
            };
        case SettingsActionTypes.get_deviceRegistrationQR.REQUEST:
            return {
                ...state,
            };
        case SettingsActionTypes.get_deviceRegistrationQR.SUCCESS:
            return {
                ...state,
                deviceRegistrationQR: payload
            };
        case SettingsActionTypes.get_deviceRegistrationQR.FAILURE:
            return {
                ...state,
            };
        case SettingsActionTypes.clear_deviceRegistrationQR.SUCCESS:
            return {
                ...state,
                deviceRegistrationQR: null
            };
        case SettingsActionTypes.get_registeredDevices.REQUEST:
            return {
                ...state,
            };
        case SettingsActionTypes.get_registeredDevices.SUCCESS:
            return {
                ...state,
                registeredDevices: payload
            };
        case SettingsActionTypes.get_registeredDevices.FAILURE:
            return {
                ...state,
            };
        case SettingsActionTypes.clear_registeredDevices.SUCCESS:
            return {
                ...state,
                registeredDevices: null
            };
        case SettingsActionTypes.del_registeredDevice.REQUEST:
            return {
                ...state,
            };
        case SettingsActionTypes.del_registeredDevice.SUCCESS:
            return {
                ...state,
                deleteRegisteredDevice: payload
            };
        case SettingsActionTypes.del_registeredDevice.FAILURE:
            return {
                ...state,
                loading: false
            };
        case SettingsActionTypes.clear_registeredDevice.SUCCESS:
            return {
                ...state,
                deleteRegisteredDevice: null
            };
        default:
            return state;
    }
};
