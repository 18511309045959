import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
} from '../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus
};

export const GET_AVAILABLE_APPLICATION_LIST_API = `${API_URL}/selfservice/api/selfservice/applications`;
export const GET_ALL_USER_TAG_API = `${API_URL}/selfservice/api/selfservice/tag`;
export const POST_NEW_TAG_API = `${API_URL}/selfservice/api/selfservice/tag`;
export const UPDATE_TAG_API = `${API_URL}/selfservice/api/selfservice/tag`;
export const DELETE_TAG_API = `${API_URL}/selfservice/api/selfservice/tag`;
export const POST_MOVE_APP_TO_USER_TAG_API = `${API_URL}/selfservice/api/selfservice/tag/moveapplication`;
export const GET_USER_TAG_APPLICATION_API = `${API_URL}/selfservice/api/selfservice/tag/applicationsList`;
export const GET_ALL_APPLICATION_API = `${API_URL}/selfservice/api/selfservice/application/applicationListAllByPage`;
export const GET_CHECK_FOR_APPLICATION_ROLE_API = `${API_URL}/provsrvc/applicationRole/findByApplicationId`;
export const POST_ASSIGN_APPLICATION_API = `${API_URL}/usersrvc/api/user/assignapplication`;
export const GET_ROLE_ASSIGNED_LIST_API = `${API_URL}/selfservice/api/selfservice/application/getRoleUnassignList`;
export const GET_AVAILABLE_UNASSIGNED_ROLES_API = `${API_URL}/selfservice/api/selfservice/application/getRoleAssignList`;
export const POST_ASSIGN_ROLES_API = `${API_URL}/usersrvc/api/user/assignapplication`;

export const ApplicationActionTypes = {
    get_availableAppList: createRequestActionTypes("GET_AVAILABLE_APP_LIST"),
    get_allUserTag: createRequestActionTypes("GET_ALL_USER_TAG"),
    post_newTag: createRequestActionTypes("POST_NEW_TAG"),
    update_tag: createRequestActionTypes("UPDATE_TAG"),
    delete_tag: createRequestActionTypes("DELETE_TAG"),
    post_moveAppToUserTag: createRequestActionTypes("POST_MOVE_APP_TO_USER_TAG"),
    get_getUserTagApplication: createRequestActionTypes("GET_USER_TAG_APPLICATION"),
    get_allApplication: createRequestActionTypes("GET_ALL_APPLICATION"),
    get_checkForApplicationRole: createRequestActionTypes("GET_CHECK_FOR_APPLICATION_ROLE"),
    post_assignApplication:  createRequestActionTypes("POST_ASSIGN_APPLICATION"),
    reset_applicationData: createRequestActionTypes("RESET_APPLICATION_DATA"),
    get_roleAssignedList: createRequestActionTypes("GET_ROLE_ASSIGNED_LIST"),
    get_availabelUnassignedRolesList: createRequestActionTypes("GET_AVAILABLE_UNASSIGNED_ROLES"),
    post_assignRoles: createRequestActionTypes("POST_ASSIGN_ROLES"),
};