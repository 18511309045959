import React, { useEffect, useState } from 'react';
import { Route, useHistory, Redirect } from "react-router-dom"
import jwt from 'jsonwebtoken'
import { getAuthToken, getRefreshToken, isDelegatedUser } from './utils/auth';
import { performRefreshToken } from './utils/api';
import { store } from '.';
import { logout } from './Views/Authenticantion/authActions';
import { deleteCorrelation } from './utils/helper';

export default function ProtectedRoute(props) {
    const { path, component } = props
    const history = useHistory()
    const authToken = getAuthToken()
    const refreshToken = getRefreshToken()
    const login = localStorage.getItem('user')
    const callRefreshToken = JSON.parse(localStorage.getItem('callRefreshToken'))
    const checkAuth = async () => {
        if(authToken && login) {
            const token = jwt.decode(authToken);
            const now = parseInt(new Date().getTime() / 1000);
            const isTokenValid = now < token.exp;
            if(!isTokenValid) {
                if(refreshToken) {
                    if(!callRefreshToken) await performRefreshToken()
                }
            }
        }else {
            localStorage.clear()
            deleteCorrelation()
            localStorage.setItem('postBackUrl', `${props.location.pathname}${props.location.search}`)
            history.push(`/auth/login`)
            store.dispatch(logout());
        }
    }
    useEffect(() => {
        checkAuth()
        return(() => {
            localStorage.removeItem('callRefreshToken')
        })
    })

    if(isDelegatedUser() && (path==="/settings" || path==="/application/request")) return ( <Redirect to="/auth" /> )

    return (
        <Route path={path} component={component}/>
    )
}