import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
} from '../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus
};

export const GET_MAILS_BY_CATEGORY_API = `${API_URL}/uiapisrvc/api/mailer/getRecentMailsByCategory`;
export const GET_DESRIPTION_BY_SUBJECT_API = `${API_URL}/uiapisrvc/api/mailer/searchMail`;
export const GET_ACTIVE_PRIVATE_ORG_VIDEO_API = `${API_URL}/uiapisrvc/api/orgvideo/GetActivePrivateOrgVideo`;
export const GET_ACTIVE_PUBLIC_ORG_VIDEO_API = `${API_URL}/uiapisrvc/api/orgvideo/GetActivePublicOrgVideo`;
export const GET_ACTIVE_PUBLIC_ANNOUNCEMENTS_API = `${API_URL}/uiapisrvc/api/announcement/GetActivePublicAnnouncements`;
export const GET_ACTIVE_PRIVATE_ANNOUNCEMENTS_API = `${API_URL}/uiapisrvc/api/announcement/GetActivePrivateAnnouncements`;
export const GET_ACTIVE_NEWS_LETTER_API = `${API_URL}/uiapisrvc/api/newsletter/getActiveNewsLetter`;

export const CommunicationActionTypes = {
    get_mailsByCategoryCorporate : createRequestActionTypes("GET_MAILS_BY_CATEGORY_CORPORATE"),
    get_mailsByCategoryBits : createRequestActionTypes("GET_MAILS_BY_CATEGORY_BITS"),
    get_mailsByCategoryHr : createRequestActionTypes("GET_MAILS_BY_CATEGORY_HR"),
    get_active_private_OrgVideo: createRequestActionTypes("GET_ACTIVE_PRIVATE_ORG_VIDEO_API"),
    get_active_public_OrgVideo: createRequestActionTypes("GET_ACTIVE_PUBLIC_ORG_VIDEO_API"),
    get_activePublicAnnouncements: createRequestActionTypes("GET_ACTIVE_PUBLIC_ANNOUNCEMENTS"),
    get_activePrivateAnnouncements: createRequestActionTypes("GET_ACTIVE_PRIVATE_ANNOUNCEMENTS"),
    get_activeNewsLetter: createRequestActionTypes("GET_ACTIVE_NEWS_LETTER"),
    get_businessExcellenceSubject: createRequestActionTypes("GET_BUSINESS_EXCELLENCE_SUBJECT"),
    get_descriptionbySubject: createRequestActionTypes("GET_BUSINESS_EXCELLENCE_DESCRIPTION_BY_SUBJECT")
};