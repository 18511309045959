import { getAuthToken } from "../utils/auth";
import { performRefreshToken } from "../utils/api"
import { defaultAppImage } from "../utils/constantImage";

export const API_URL = "";

const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";

export const PositiveIntegerRegex = /^[1-9]\d*$/;


export function actionCreator(actionType, data) {
    return {
        type: actionType,
        payload: data
    };
}

export function createRequestActionTypes(base) {
    return [REQUEST, SUCCESS, FAILURE].reduce((requestTypes, type) => {
        requestTypes[type] = `${base}_${type}`;
        return requestTypes;
    }, {});
}

export function checkHttpStatus(response) {
    if (response.status >= 200 && response.status < 204) {
        return response.json();
    } else if (response.status === 204) {
        return true;
    } else if (response.status === 403 ) {
        // return performRefreshToken();
    } else if (response.status >= 400 && response.status <= 500) {
        return response.json();
    } else {
        var error = new Error(response.statusText);
        error.response = response;
        throw error;
    }
}

export const jsonApiHeader = (contentType) => {
    const token = getAuthToken()
    return {
        'Accept': 'application/json',
        'content-type': contentType === true ? 'application/json' : '',
        Authorization: `Bearer ${token}`,
        // 'clientip': process.env.IpAddress
        // tenant: 'unotech',
        // user: userid ? userid : ''
    };
};

export const parseJwt = () => {
    const token = getAuthToken()
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export async function callImageApi(appId) {
    return new Promise((resolve, reject) => {
        const key = `appImage:${appId}`

        // check the cache
        if (localStorage.getItem(key)) {
            try {
                const item = JSON.parse(localStorage.getItem(key))
                // 1 day cache
                if ((Number(item.date) + (86400 * 1000)) > Date.now()) return resolve(item.image)
            } catch (error) {
                // ignore any other error
            }
        }

        return callApi(`/provsrvc/applicationTenant/getApp/icon/${appId}`, 'GET')
            .then(e => {
                if (e.success && e.data && e.data.icon && e.data.icon.data) {
                    const image = e.data.icon.data
                    localStorage.setItem(key, JSON.stringify({ date: Date.now(), image }))
                    return resolve(image)
                }

                localStorage.setItem(key, JSON.stringify({ date: Date.now(), image: null }))
                return resolve(null)
            })
            .catch(reject)
    })
        .then(icon => {
            const img = icon ? `data:image/jpeg;base64,${icon}` : `data:image/png;base64,${defaultAppImage}`
            return img
        })
}

export async function callApi(endpoint, method = 'GET', body) {
    // const headers = {
    //     'Accept': 'application/json',
    // }

    const token = getAuthToken()
    const headers = {
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
        // 'clientip': process.env.IpAddress
    }

    if (body) headers['content-type'] = 'application/json'


    return fetch(endpoint, {
        headers,
        method,
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (response.status === 503) {
                // showError('Server is down: HTTP 503')
                // console.log('Server is down: HTTP 503')
                return Promise.reject('Server is down: HTTP 503')
            }

            if (response.status === 500) {
                // showError('Internal Server Error: HTTP 500')
                // console.log('Internal Server Error: HTTP 500')
                return Promise.reject('Internal Server Error: HTTP 500')
            }

            if (response.status === 208) {
                // showError('User status is active. Contact Cymmetri administrator. : HTTP 208')
                // console.log('User status is active. Contact Cymmetri administrator. : HTTP 208')
                return Promise.reject('Active User')
            }

            if (!response.ok && response.status !== 404) return response.json()
                .then(json => {
                    // add error code mapping over here
                    // const value = search(json.errorCode);
                    // const message = value ? value : json.errorCode
                    // showError(message)
                    // console.log('error---')
                    return Promise.reject(json)
                })
                .then(json => Promise.reject(json))
            return response.json()
        })
}



export const preventBack = ()=>{
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
    window.history.pushState(null, document.title,  window.location.href);

}); 
}

export const trimString = ( text, len ) => {
    if (text && text.length > len){
        return `${text.substring(0, len)}...`
    } else {
        return text
    }
}