import { UserProfileTypes } from '../constants/index';

const initialState = {
    loading: false,
    userDetails: null,
    userProfileImage: null,
    userLogout: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case UserProfileTypes.get_userProfile.REQUEST:
            return {
                ...state,
                loading: true
            };
        case UserProfileTypes.get_userProfile.SUCCESS:
            return {
                ...state,
                loading: false,
                userDetails: payload
            };
        case UserProfileTypes.get_userProfile.FAILURE:
            return {
                ...state,
                loading: false
            };
        case UserProfileTypes.get_userProfileImage.REQUEST:
            return {
                ...state,
                loading: true
            };
        case UserProfileTypes.get_userProfileImage.SUCCESS:
            return {
                ...state,
                loading: false,
                userProfileImage: payload
            };
        case UserProfileTypes.get_userProfileImage.FAILURE:
            return {
                ...state,
                loading: false
            };
        case UserProfileTypes.post_userLogout.REQUEST:
            return {
                ...state,
                loading: true
            };
        case UserProfileTypes.post_userLogout.SUCCESS:
            return {
                ...state,
                loading: false,
                userLogout: payload
            };
        case UserProfileTypes.post_userLogout.FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};
