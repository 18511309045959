import { NotificationActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    notificationList: null,
    recentlyUsedApp: null,
    frequentlyUsedApp: null,
    notificationUnreadCount: null,
    markNotificationRead: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case NotificationActionTypes.get_notificationList.REQUEST:
            return {
                ...state,
                loading: true
            };
        case NotificationActionTypes.get_notificationList.SUCCESS:
            return {
                ...state,
                loading: false,
                notificationList: payload
            };
        case NotificationActionTypes.get_notificationList.FAILURE:
            return {
                ...state,
                loading: false
            };
        case NotificationActionTypes.get_recentlyUsedApp.REQUEST:
            return {
                ...state,
                loading: true
            };
        case NotificationActionTypes.get_recentlyUsedApp.SUCCESS:
            return {
                ...state,
                loading: false,
                recentlyUsedApp: payload
            };
        case NotificationActionTypes.get_recentlyUsedApp.FAILURE:
            return {
                ...state,
                loading: false
            };
        case NotificationActionTypes.put_frequentlyUsedApp.REQUEST:
            return {
                ...state,
                loading: true
            };
        case NotificationActionTypes.put_frequentlyUsedApp.SUCCESS:
            return {
                ...state,
                loading: false,
                frequentlyUsedApp: payload
            };
        case NotificationActionTypes.put_frequentlyUsedApp.FAILURE:
            return {
                ...state,
                loading: false
            };
        case NotificationActionTypes.get_notificationUnreadCount.REQUEST:
            return {
                ...state,
                loading: true
            };
        case NotificationActionTypes.get_notificationUnreadCount.SUCCESS:
            return {
                ...state,
                loading: false,
                notificationUnreadCount: payload
            };
        case NotificationActionTypes.get_notificationUnreadCount.FAILURE:
            return {
                ...state,
                loading: false
            };
        case NotificationActionTypes.put_markNotificationRead.REQUEST:
            return {
                ...state,
                loading: true
            };
        case NotificationActionTypes.put_markNotificationRead.SUCCESS:
            return {
                ...state,
                loading: false,
                markNotificationRead: payload
            };
        case NotificationActionTypes.put_markNotificationRead.FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};
