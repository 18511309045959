import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
} from '../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus
};

// export const GET_UNREAD_REQUEST_COUNT_API = `${API_URL}/workflowsrvc/api/workflowtaskassignment/unreadCount`;
export const GET_UNREAD_REQUEST_COUNT_API = `${API_URL}/workflowsrvc/workflowTask/unreadCount`;
// export const GET_USER_LIST_API = `${API_URL}/workflowsrvc/api/workflowtaskassignment/user/list`;
export const GET_USER_LIST_API = `${API_URL}/workflowsrvc/workflowTask/user/list`;
// export const GET_TASK_BODY_FOR_APPROVAL_API = `${API_URL}/workflowsrvc/api/workflowtaskassignment`;
export const GET_TASK_BODY_FOR_APPROVAL_API = `${API_URL}/workflowsrvc/workflowTask`;
// export const POST_MARK_STAR_API = `${API_URL}/workflowsrvc/api/workflowtaskassignment/markStarred`;
export const POST_MARK_STAR_API = `${API_URL}/workflowsrvc/workflowTask/markStarred`;
// export const POST_MARK_READ_API = `${API_URL}/workflowsrvc/api/workflowtaskassignment/markRead`;
export const POST_MARK_READ_API = `${API_URL}/workflowsrvc/workflowTask/markRead`;
// export const POST_APPROVE_REJECT_TASK_API = `${API_URL}/workflowsrvc/api/workflowtaskassignment`;
export const POST_APPROVE_REJECT_TASK_API = `${API_URL}/workflowsrvc/workflowTask`;
export const NEW_POST_APPROVE_REJECT_TASK_API = `${API_URL}/workflowsrvc/workflowTask`;
// export const GET_LIST_STARRED_ITEMS_API = `${API_URL}/workflowsrvc/api/workflowtaskassignment/starredlist`;
export const GET_LIST_STARRED_ITEMS_API = `${API_URL}/workflowsrvc/workflowTask/starredlist`;
export const GET_CLAIM_LIST_API = `${API_URL}/workflowsrvc/api/workflowtaskassignment/list`; // need to discuss
// export const POST_CLAIM_TASK_API = `${API_URL}/workflowsrvc/api/workflowtaskassignment/claimTask`;
export const POST_CLAIM_TASK_API = `${API_URL}/workflowsrvc/workflowTask/claimTask`;
// export const POST_ARCHIVED_LIST_API = `${API_URL}/workflowsrvc/api/workflowtaskassignment/archivedlist`;
export const POST_ARCHIVED_LIST_API = `${API_URL}/workflowsrvc/workflowTask/archivedlist`;

// export const GET_OPEN_MY_REQUEST_LIST_API = `${API_URL}/workflowsrvc/api/workflowtaskassignment/requestor/list`;
export const GET_OPEN_MY_REQUEST_LIST_API = `${API_URL}/workflowsrvc/workflowTask/requestor/list`;
export const GET_USER_FOR_INBOX_SEARCH_API = `${API_URL}/usersrvc/api/user/list`;

export const InboxProfileTypes = {
    get_unreadRequestCount: createRequestActionTypes("GET_UNREAD_REQUEST_COUNT"),
    get_unreadClaimCount: createRequestActionTypes("GET_UNREAD_CLAIM_COUNT"),
    get_userList : createRequestActionTypes("GET_USER_LIST"),
    get_taskBodyForApproval : createRequestActionTypes("GET_TASK_BODY_FOR_APPROVAL"),
    post_markStar : createRequestActionTypes("POST_MARK_STAR"),
    post_markRead : createRequestActionTypes("POST_MARK_READ"),
    get_starredListItems : createRequestActionTypes("GET_LIST_STARRED_ITEMS"),
    post_approveRejectTask : createRequestActionTypes("POST_APPROVE_REJECT_TASK"),
    get_claimList : createRequestActionTypes("GET_CLAIM_LIST"),
    post_claimTask : createRequestActionTypes("POST_CLAIM_TASK"),
    get_archivedList : createRequestActionTypes("GET_ARCHIVED_LIST"),
    get_openMyRequestList : createRequestActionTypes("GET_OPEN_MY_REQUEST_LIST"),
    get_userForInboxSearch : createRequestActionTypes("GET_USER_FOR_INBOX_SEARCH"),
};