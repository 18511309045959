import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
} from '../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus
};

export const GET_ALL_CAMPAIGN_LIST_API = `${API_URL}/igsrvc/api/ig/campaign/approver/list`;
export const GET_ALL_EXECUTION_API = `${API_URL}/igsrvc/api/ig/campaign/history/execution`;
export const GET_USERS_CAMPAIGN_LISTVIEW_API = `${API_URL}/igsrvc/api/ig/campaignassignment/approver/listview`;
export const GET_USERS_CAMPAIGN_GRIDVIEW_API = `${API_URL}/igsrvc/api/ig/campaignassignment/approver/gridview`;
export const PUT_CAMPAIGN_STAGE_REVIEWER_API = `${API_URL}/igsrvc/api/ig/campaignassignments`;
export const POST_CAMPAIGN_ACTIVE_SUMMARY_API = `${API_URL}/igsrvc/api/ig/campaign/execution/list-summary/reviewer`;
export const POST_CAMPAIGN_MANAGED_SUMMARY_API = `${API_URL}/igsrvc/api/ig/campaign/execution/list-summary/manager`;
export const POST_CAMPAIGN_ACTIVE_ASSIGNMENTS_API = `${API_URL}/igsrvc/api/ig/campaign/assignment/list-derived/reviewer`;
export const POST_CAMPAIGN_MANAGED_ASSIGNMENTS_API = `${API_URL}/igsrvc/api/ig/campaign/assignment/list`;
export const POST_CAMPAIGN_HISTORY_SUMMARY_API = `${API_URL}/igsrvc/api/ig/campaign/execution/history/list-summary/reviewer`;
export const POST_CAMPAIGN_HISTORY_ASSIGNMENTS_API = `${API_URL}/igsrvc/api/ig/campaign/assignment/history/list-derived/reviewer`;
export const APPROVE_ASSIGNMENTS_API = `${API_URL}/igsrvc/api/ig/campaign/assignment/approve`;
export const REJECT_ASSIGNMENTS_API = `${API_URL}/igsrvc/api/ig/campaign/assignment/reject`;
export const POST_SEND_CAMPAIGN_REPORT = `${API_URL}/reportsrvc/reports/export/csv`;


export const CampaignActionTypes = {
    get_AllCampaignLists : createRequestActionTypes("GET_ALL_CAMPAIGN_LIST"),
    clear_AllCampaignLists : createRequestActionTypes("CLEAR_ALL_CAMPAIGN_LIST"),
    get_AllExecutionLists : createRequestActionTypes("GET_ALL_EXECUTION_LIST"),
    clear_AllExecutionLists : createRequestActionTypes("CLEAR_ALL_EXECUTION_LIST"),
    get_UsersCampaignListView : createRequestActionTypes("GET_USERS_CAMPAIGN_LISTVIEW"),
    clear_UsersCampaignListView : createRequestActionTypes("CLEAR_USERS_CAMPAIGN_LISTVIEW"),
    get_UsersCampaignGridView : createRequestActionTypes("GET_USERS_CAMPAIGN_GRIDVIEW"),
    clear_UsersCampaignGridView : createRequestActionTypes("CLEAR_USERS_CAMPAIGN_GRIDVIEW"),
    put_CampaignStageReviewer : createRequestActionTypes("PUT_CAMPAIGN_STAGE_REVIEWER"),
    clear_CampaignStageReviewer : createRequestActionTypes("CLEAR_CAMPAIGN_STAGE_REVIEWER"),
    post_CampaignActiveSummary : createRequestActionTypes("POST_CAMPAIGN_ACTIVE_SUMMARY"),
    post_CampaignManagedSummary : createRequestActionTypes("POST_CAMPAIGN_MANAGED_SUMMARY"),
    clear_CampaignActiveSummary : createRequestActionTypes("CLEAR_CAMPAIGN_ACTIVE_SUMMARY"),
    clear_CampaignManagedSummary : createRequestActionTypes("CLEAR_CAMPAIGN_MANAGED_SUMMARY"),
    post_CampaignHistorySummary : createRequestActionTypes("POST_CAMPAIGN_HISTORY_SUMMARY"),
    clear_CampaignHistorySummary : createRequestActionTypes("CLEAR_CAMPAIGN_HISTORY_SUMMARY"),
    post_CampaignActiveAssignments : createRequestActionTypes("POST_CAMPAIGN_ACTIVE_ASSIGNMENTS"),
    post_CampaignManagedList : createRequestActionTypes("POST_CAMPAIGN_MANAGED_LIST"),
    clear_CampaignActiveAssignments : createRequestActionTypes("CLEAR_CAMPAIGN_ACTIVE_ASSIGNMENTS"),
    clear_CampaignManagedList : createRequestActionTypes("CLEAR_CAMPAIGN_MANAGED_LIST"),
    post_CampaignHistoryAssignments : createRequestActionTypes("POST_CAMPAIGN_HISTORY_ASSIGNMENTS"),
    clear_CampaignHistoryAssignments : createRequestActionTypes("CLEAR_CAMPAIGN_HISTORY_ASSIGNMENTS"),
    post_ApproveAssignments : createRequestActionTypes("POST_APPROVE_ASSIGNMENTS"),
    clear_ApproveAssignments : createRequestActionTypes("CLEAR_APPROVE_ASSIGNMENTS"),
    post_RejectAssignments : createRequestActionTypes("POST_REJECT_ASSIGNMENTS"),
    clear_RejectAssignments : createRequestActionTypes("CLEAR_REJECT_ASSIGNMENTS"),
};