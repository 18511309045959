import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
} from '../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus
};

export const GET_USER_PROFILE_API = `${API_URL}/selfservice/api/selfservice/`;
export const GET_USER_PROFILE_IMAGE_API = `${API_URL}/selfservice/api/selfservice/userprofile`;
export const POST_USER_LOGOUT_ACTION_API = `${API_URL}/authsrvc/auth/logout`;


export const UserProfileTypes = {
    get_userProfile : createRequestActionTypes("GET_USER_PROFILE"),
    get_userProfileImage : createRequestActionTypes("GET_USER_PROFILE_IMAGE"),
    post_userLogout : createRequestActionTypes("POST_USER_LOGOUT_ACTION"),
};