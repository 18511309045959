import React, { Component, lazy, Suspense } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Notifier from './shared/Notifier/Notifier';
import ProtectedRoute from './ProtectedRoute';
import { isDelegatedUser } from './utils/auth';
// import SAMLIDPSSO from './Views/SSO/SAMLIDPSSO';
import { Box, CircularProgress } from '@material-ui/core';
const partnerName = process.env.REACT_APP_PARTNER_NAME || 'CYM';

function getFaviconEl() {
  return document.getElementById('favicon');
}

class App extends Component {
  customLogin = process.env.REACT_APP_CUSTOM_LOGIN;

  componentDidMount = () => {
    // const favicon = getFaviconEl();
    // favicon.href = partnerName === 'MHD' ? "/favicon.ico" : "/fav.ico";
    document.title =
      partnerName === 'MHD' ? 'SSO Portal' : 'Cymmetri - Self Service';
  };

  Loader = () => {
    return (
      <Box
        style={{ flexGrow: '1' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={'100vh'}
        width={'100%'}
      >
        <CircularProgress />
      </Box>
    );
  };

  render() {
    return (
      <>
        <Notifier />
        <Router>
          <Suspense fallback={this.Loader()}>
            <Switch>
              <ProtectedRoute
                exact={true}
                path="/welcome"
                component={lazy(() => import('./Views/Welcome/Welcome'))}
              />
              {partnerName === 'MHD' ? (
                <Route
                  path="/auth"
                  component={lazy(() =>
                    import('./Views/Authenticantion/MahindraIndex')
                  )}
                />
              ) : (
                <Route
                  path="/auth"
                  component={lazy(() => import('./Views/Authenticantion'))}
                />
              )}

              {/* To do : Need to create a function in helperjs which will return the custom login component based on env file */}
              {this.customLogin === 'Injazat' && (
                <Route
                  path="/login"
                  component={lazy(() =>
                    import('./Views/Authenticantion/Injazat/SignIn')
                  )}
                />
              )}
              {this.customLogin === 'BSE' && (
                <Route
                  path="/login"
                  component={lazy(() =>
                    import('./Views/Authenticantion/BSELogin')
                  )}
                />
              )}
              <ProtectedRoute
                exact={true}
                path="/application"
                component={lazy(() =>
                  import('./Views/Application/application')
                )}
              />
              <ProtectedRoute
                exact={true}
                path="/application/request"
                component={lazy(() =>
                  import('./Views/Application/RequestApp/RequestApp')
                )}
              />
              <ProtectedRoute
                exact={true}
                path="/application/mfa"
                component={lazy(() => import('./Views/Application/MFA'))}
              />
              <ProtectedRoute
                exact={true}
                path="/application/mfa/verify/:id"
                component={lazy(() =>
                  import('./Views/Application/MFA/MfaComponents/VerifyMfa')
                )}
              />
              <ProtectedRoute
                exact={true}
                path="/application/mfa/registerMfa"
                component={lazy(() =>
                  import('./Views/Application/MFA/MfaComponents/RegisterMfa')
                )}
              />
              <ProtectedRoute
                exact={true}
                path="/application/mfa/registerMfa/mfa/:id"
                component={lazy(() =>
                  import(
                    './Views/Application/MFA/MfaComponents/RegisterMfa/RegisterFactor'
                  )
                )}
              />
              <ProtectedRoute
                exact={true}
                path="/application/mfa/registerMfa/genQR/:id"
                component={lazy(() =>
                  import(
                    './Views/Application/MFA/MfaComponents/RegisterMfa/GenerateQr'
                  )
                )}
              />
              <ProtectedRoute
                exact={true}
                path="/application/mfa/registerMfa/PushAuthenticator"
                component={lazy(() =>
                  import(
                    './Views/Application/MFA/MfaComponents/RegisterMfa/RegisterPushAuthenticator'
                  )
                )}
              />
              <ProtectedRoute
                path="/inbox"
                component={lazy(() => import('./Views/Inbox/Inbox'))}
              />
              <ProtectedRoute
                exact={true}
                path="/profile"
                component={lazy(() => import('./Views/Profile/Profile'))}
              />
              <ProtectedRoute
                path="/settings"
                component={lazy(() => import('./Views/Settings/settings'))}
              />
              <ProtectedRoute
                path="/campaign"
                component={lazy(() => import('./Views/Campaign/Campaign'))}
              />
              <ProtectedRoute
                path="/communication"
                component={lazy(() =>
                  import('./Views/Communication/Comunication')
                )}
              />
              <ProtectedRoute
                exact
                path="/team"
                component={lazy(() => import('./Views/Team/MyTeam/MyTeam'))}
              />
              <ProtectedRoute
                path="/team/member"
                component={lazy(() =>
                  import('./Views/Team/MemberDetails/MemberDetails')
                )}
              />
              <ProtectedRoute
                path="/sso/openid"
                component={lazy(() => import('./Views/SSO/OpenID'))}
              />
              <Route
                path="/sso"
                component={lazy(() =>
                  import('./Views/Authenticantion/SSO.jsx')
                )}
              />
              <Route
                path="/SingleSignOnService"
                component={lazy(() => import('./Views/SSO/SamlSSO'))}
              />
              <Route
                path="/error"
                component={lazy(() =>
                  import('./Views/ErrorPages/PageNotFound')
                )}
              />
              <Route
                path="/err"
                component={lazy(() => import('./Views/ErrorPages/SSOError'))}
              />
              <Route
                path="/apiSSO"
                component={lazy(() => import('./Views/SSO/ApiSSO'))}
              />
              <Route
                path="/samlLogin"
                component={lazy(() => import('./Views/SSO/SamlLogin'))}
              />
              <Route
                path="/logout"
                component={lazy(() => import('./Views/Logout'))}
              />
              {this.customLogin === undefined ? (
                <Redirect to="/auth" />
              ) : (
                <Redirect to="/login" />
              )}
            </Switch>
          </Suspense>
        </Router>
      </>
    );
  }
}

export default App;
