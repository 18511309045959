import { CampaignActionTypes } from '../constants/index';

const initialState = {
    // loading: false,
    campaigns: null,
    executions: null,
    userCampaignListView: null,
    userCampaignGridView: null,
    campaignStageReviewer: null,
    activeSummary: null,
    managedSummary: null,
    historySummary: null,
    activeAssignments: null,
    managedAccessList: null,
    historyAssignments: null,
    approveAssignments: null,
    rejectAssignments: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CampaignActionTypes.get_AllCampaignLists.REQUEST:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.get_AllCampaignLists.SUCCESS:
            return {
                ...state,
                campaigns: payload
            };
        case CampaignActionTypes.get_AllCampaignLists.FAILURE:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.clear_AllCampaignLists.SUCCESS:
          return {
              ...state,
              campaigns: null
            };
          case CampaignActionTypes.get_AllExecutionLists.REQUEST:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.get_AllExecutionLists.SUCCESS:
            return {
                ...state,
                executions: payload
            };
        case CampaignActionTypes.get_AllExecutionLists.FAILURE:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.clear_AllExecutionLists.SUCCESS:
          return {
              ...state,
              executions: null
          };
        case CampaignActionTypes.get_UsersCampaignListView.REQUEST:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.get_UsersCampaignListView.SUCCESS:
            return {
                ...state,
                userCampaignListView: payload
            };
        case CampaignActionTypes.get_UsersCampaignListView.FAILURE:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.clear_UsersCampaignListView.SUCCESS:
          return {
              ...state,
              userCampaignListView: null
          };
        case CampaignActionTypes.get_UsersCampaignGridView.REQUEST:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.get_UsersCampaignGridView.SUCCESS:
            return {
                ...state,
                userCampaignGridView: payload
            };
        case CampaignActionTypes.get_UsersCampaignGridView.FAILURE:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.clear_UsersCampaignGridView.SUCCESS:
          return {
              ...state,
              userCampaignGridView: null
          };
        case CampaignActionTypes.put_CampaignStageReviewer.REQUEST:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.put_CampaignStageReviewer.SUCCESS:
            return {
                ...state,
                campaignStageReviewer: payload
            };
        case CampaignActionTypes.put_CampaignStageReviewer.FAILURE:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_CampaignActiveSummary.REQUEST:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_CampaignActiveSummary.SUCCESS:
            return {
                ...state,
                activeSummary: payload
            };
        case CampaignActionTypes.post_CampaignActiveSummary.FAILURE:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_CampaignManagedSummary.REQUEST:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_CampaignManagedSummary.SUCCESS:
            return {
                ...state,
                managedSummary: payload
            };
        case CampaignActionTypes.post_CampaignManagedSummary.FAILURE:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_CampaignHistorySummary.REQUEST:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_CampaignHistorySummary.SUCCESS:
            return {
                ...state,
                historySummary: payload
            };
        case CampaignActionTypes.post_CampaignHistorySummary.FAILURE:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_CampaignActiveAssignments.REQUEST:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_CampaignActiveAssignments.SUCCESS:
            return {
                ...state,
                activeAssignments: payload
            };
        case CampaignActionTypes.post_CampaignActiveAssignments.FAILURE:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_CampaignManagedList.REQUEST:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_CampaignManagedList.SUCCESS:
            return {
              ...state,
              managedAccessList: payload,
            };
        case CampaignActionTypes.post_CampaignManagedList.FAILURE:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_CampaignHistoryAssignments.REQUEST:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_CampaignHistoryAssignments.SUCCESS:
            return {
                ...state,
                historyAssignments: payload
            };
        case CampaignActionTypes.post_CampaignHistoryAssignments.FAILURE:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_ApproveAssignments.REQUEST:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_ApproveAssignments.SUCCESS:
            return {
                ...state,
                approveAssignments: payload
            };
        case CampaignActionTypes.post_ApproveAssignments.FAILURE:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_RejectAssignments.REQUEST:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.post_RejectAssignments.SUCCESS:
            return {
                ...state,
                rejectAssignments: payload
            };
        case CampaignActionTypes.post_RejectAssignments.FAILURE:
            return {
                ...state,
                // loading: true
            };
        case CampaignActionTypes.clear_CampaignStageReviewer.SUCCESS:
            return {
                ...state,
                campaignStageReviewer: null
            }
        case CampaignActionTypes.clear_CampaignActiveSummary.SUCCESS:
            return {
                ...state,
                activeSummary: null
            }
        case CampaignActionTypes.clear_CampaignManagedSummary.SUCCESS:
            return {
                ...state,
                managedSummary: null
            }
        case CampaignActionTypes.clear_CampaignHistorySummary.SUCCESS:
            return {
                ...state,
                historySummary: null
            }
        case CampaignActionTypes.clear_CampaignActiveAssignments.SUCCESS:
            return {
                ...state,
                activeAssignments: null
            }
        case CampaignActionTypes.clear_CampaignManagedList.SUCCESS:
            return {
              ...state,
              managedAccessList: null,
            };
        case CampaignActionTypes.clear_CampaignHistoryAssignments.SUCCESS:
            return {
                ...state,
                historyAssignments: null
            }
        case CampaignActionTypes.clear_ApproveAssignments.SUCCESS:
            return {
                ...state,
                approveAssignments: null
            }
        case CampaignActionTypes.clear_RejectAssignments.SUCCESS:
            return {
                ...state,
                rejectAssignments: null
            }
        default:
            return state;
    }
};
