import { ApplicationActionTypes } from '../constants/index';

const initialState = {
    loading: false,
    loadingUserTag: false,
    availableAppList: null,
    allAppList: null,
    allUserTag: null,
    createNewTag: null,
    updateTag: null,
    deleteTag: null,
    moveAppToUserTag: null,
    checkForApplicationRole: null,
    assignedApplication: null,
    roleAssignedList: null,
    availabelUnassignedRolesList: null,
    assignRoles: null
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ApplicationActionTypes.get_availableAppList.REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ApplicationActionTypes.get_availableAppList.SUCCESS:
            return {
                ...state,
                loading: false,
                availableAppList: payload
            };
        case ApplicationActionTypes.get_availableAppList.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case ApplicationActionTypes.get_allUserTag.REQUEST:
            return {
                ...state,
                loadingUserTag: true
            };
        case ApplicationActionTypes.get_allUserTag.SUCCESS:
            return {
                ...state,
                loadingUserTag: false,
                allUserTag: payload
            };
        case ApplicationActionTypes.get_allUserTag.FAILURE:
            return {
                ...state,
                loadingUserTag: false
            };
        case ApplicationActionTypes.post_newTag.REQUEST:
            return {
                ...state,
                loading: true,
                loadingUserTag: true
            };
        case ApplicationActionTypes.post_newTag.SUCCESS:
            return {
                ...state,
                loading: false,
                loadingUserTag: false,
                createNewTag: payload
            };
        case ApplicationActionTypes.post_newTag.FAILURE:
            return {
                ...state,
                loading: false,
                loadingUserTag: false
            };
        case ApplicationActionTypes.update_tag.REQUEST:
            return {
                ...state,
                loading: true,
                loadingUserTag: true,
            };
        case ApplicationActionTypes.update_tag.SUCCESS:
            return {
                ...state,
                loading: false,
                loadingUserTag: false,
                updateTag: payload
            };
        case ApplicationActionTypes.update_tag.FAILURE:
            return {
                ...state,
                loadingUserTag: false,
                loading: false
            };
        case ApplicationActionTypes.delete_tag.REQUEST:
            return {
                ...state,
                loading: true,
                loadingUserTag: true,
            };
        case ApplicationActionTypes.delete_tag.SUCCESS:
            return {
                ...state,
                loading: false,
                loadingUserTag: false,
                deleteTag: payload
            };
        case ApplicationActionTypes.delete_tag.FAILURE:
            return {
                ...state,
                loading: false,
                loadingUserTag: false,
            };
        case ApplicationActionTypes.post_moveAppToUserTag.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ApplicationActionTypes.post_moveAppToUserTag.SUCCESS:
            return {
                ...state,
                loading: false,
                moveAppToUserTag: payload
            };
        case ApplicationActionTypes.post_moveAppToUserTag.FAILURE:
            return {
                ...state,
                loading: false
            };
        case ApplicationActionTypes.get_getUserTagApplication.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ApplicationActionTypes.get_getUserTagApplication.SUCCESS:
            return {
                ...state,
                loading: false,
                availableAppList: payload
            };
        case ApplicationActionTypes.get_getUserTagApplication.FAILURE:
            return {
                ...state,
                loading: false
            };
        case ApplicationActionTypes.get_allApplication.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ApplicationActionTypes.get_allApplication.SUCCESS:
            return {
                ...state,
                loading: false,
                allAppList: payload
            };
        case ApplicationActionTypes.get_allApplication.FAILURE:
            return {
                ...state,
                loading: false
            };
        case ApplicationActionTypes.get_checkForApplicationRole.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ApplicationActionTypes.get_checkForApplicationRole.SUCCESS:
            return {
                ...state,
                loading: false,
                checkForApplicationRole: payload
            };
        case ApplicationActionTypes.get_checkForApplicationRole.FAILURE:
            return {
                ...state,
                loading: false
            };
        case ApplicationActionTypes.post_assignApplication.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ApplicationActionTypes.post_assignApplication.SUCCESS:
            return {
                ...state,
                loading: false,
                assignedApplication: payload
            };
        case ApplicationActionTypes.post_assignApplication.FAILURE:
            return {
                ...state,
                loading: false
            };
        case ApplicationActionTypes.reset_applicationData.SUCCESS:
            return {
                ...state,
                loading: false,
                availableAppList: payload
            };
        case ApplicationActionTypes.get_roleAssignedList.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ApplicationActionTypes.get_roleAssignedList.SUCCESS:
            return {
                ...state,
                loading: false,
                roleAssignedList: payload
            };
        case ApplicationActionTypes.get_roleAssignedList.FAILURE:
            return {
                ...state,
                loading: false,
                roleAssignedList: null
            };
        case ApplicationActionTypes.get_availabelUnassignedRolesList.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ApplicationActionTypes.get_availabelUnassignedRolesList.SUCCESS:
            return {
                ...state,
                loading: false,
                availabelUnassignedRolesList: payload
            };
        case ApplicationActionTypes.get_availabelUnassignedRolesList.FAILURE:
            return {
                ...state,
                loading: false,
                availabelUnassignedRolesList: null
            };
        case ApplicationActionTypes.post_assignRoles.REQUEST:
            return {
                ...state,
                loading: true
            };
        case ApplicationActionTypes.post_assignRoles.SUCCESS:
            return {
                ...state,
                loading: false,
                assignRoles: payload
            };
        case ApplicationActionTypes.post_assignRoles.FAILURE:
            return {
                ...state,
                loading: false,
                assignRoles: null
            };
        default:
            return state;
    }
};
