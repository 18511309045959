import React from 'react'
import { store } from '../index'
import { enqueueSnackbarAction } from '../shared/Notifier/snackbarActions'


export const showSuccess = (msg?: string,key?: string) => {
  store.dispatch(enqueueSnackbarAction({
    message: msg,
    options: {
      // key: new Date().getTime() + Math.random(),
      key,
      variant: 'success',
      // action: (key: any) => <Button onClick={() => store.dispatch(closeSnackbarAction(key))}>x</Button>
    }
  }))
}


export const showError = (msg?: string,key?: string) => {
  store.dispatch(enqueueSnackbarAction({
    message: msg,
    options: {
      // key: new Date().getTime() + Math.random(),
      key,
      variant: 'error',
      // action: (key: any) => <Button onClick={() => store.dispatch(closeSnackbarAction(key))}>x</Button>
    }
  }))
}
export const showWarning = (msg?: string,key?: string) => {
  store.dispatch(enqueueSnackbarAction({
    message: msg,
    options: {
      // key: new Date().getTime() + Math.random(),
      key,
      variant: 'warning',
    }
  }))
}