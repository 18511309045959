import {
    actionCreator,
    API_URL,
    checkHttpStatus,
    createRequestActionTypes,
    jsonApiHeader,
} from '../../../shared/utility';

export {
    jsonApiHeader,
    actionCreator,
    checkHttpStatus
};

export const GET_NOTIFICATION_LIST_API = `${API_URL}/notification/api/notification`;
export const GET_RECENTLY_USED_APP_API = `${API_URL}/selfservice/api/selfservice/tag/frequentHitApps`;
export const PUT_FREQUENTLY_USED_APP_API = `${API_URL}/selfservice/api/selfservice/tag/frequentHit`;
export const GET_UNREAD_NOTIFICATION_API = `${API_URL}/notification/api/notification/unreadCount`;
export const PUT_MARK_READ_NOTIFICATION_API = `${API_URL}/notification/api/notification`;



export const NotificationActionTypes = {
    get_notificationList: createRequestActionTypes("GET_NOTIFICATION_LIST"),
    get_recentlyUsedApp: createRequestActionTypes("GET_RECENTLY_USED_APP"),
    put_frequentlyUsedApp: createRequestActionTypes("GET_FREQUENTLY_USED_APP"),
    get_notificationUnreadCount: createRequestActionTypes("GET_UNREAD_NOTIFICATION"),
    put_markNotificationRead: createRequestActionTypes("PUT_MARK_READ_NOTIFICATION"),
};