import { callApi } from "./api";

const moment = require("moment-timezone");
const timeZone = process.env.REACT_APP_TIME_ZONE || 'Asia/Kolkata';


/**
 * API returns invalid UTC date, Add Z at the end to properly parse it
 * @param {Date|string|undefined|null} input String received from API
 * @returns {Date|null} JS Date with proper UTC
 */
export function getDateWithTimezone(input) {
  if (input instanceof Date) {
    return input;
  }
  if (input) {
    return input.toUpperCase().includes('Z')
      ? new Date(input)
      : new Date(input + 'Z');
  }
  return null;
}

export const getFormatedDate = (date, dateFormat) => {

  if (dateFormat) {
    return moment.utc(date).tz(timeZone).format(dateFormat);
  }
  return moment.utc(date).tz(timeZone).format('DD-MM-YYYY HH:mm:ss');
}

export const getISODatetime = (date) => {
  let newDate = moment.utc(date).tz(timeZone).format();
  let splitDate = newDate.slice(0,16);
  return splitDate;
}

export const getDaysCountfromNow = (date) => {
  return moment.utc(date).tz(timeZone).fromNow();
}

export const dateTimeToIST = (date, days) => {

  if(days) {
    return moment.utc(date).tz(timeZone).fromNow();
  }

  let newDate = moment.utc(date).tz(timeZone).format('DD-MM-YYYY HH:mm:ss');
  return newDate;
}

export const dateToIST = (date) => {

  let newDate = moment.utc(date).tz(timeZone).format('DD-MM-YYYY');
  return newDate;
}

export const dateDiff = (date) => {

  let newDate = moment().diff(date, 'years');
  return newDate;
}

export const getFormattedAccessDate = (date) => {
  const validDate = getDateWithTimezone(date);
  let newDate = moment
    .utc(validDate)
    .tz(timeZone)
    .format('DD-MMM-YYYY hh:mm A');
  return newDate;
}

export const getCorrelation = () => {
  callApi(`/utilsrvc/correlation/pub`, "GET")
    .then((response) => {
      console.log(response)
    })
    .catch((err) => {
      console.log('err', err)
    })
}

export const deleteCorrelation = () => {
  callApi(`/utilsrvc/correlation/pub`, "DELETE")
    .then((response) => {
      console.log(response)
    })
    .catch((err) => {
      console.log('err', err)
    })
}

// export const setPostBackUrl = (props) => {
//   const postBackUrl = `${props.history.location.pathname}${props.history.location.search}`
//   localStorage.setItem('postBackUrl', postBackUrl)
// }