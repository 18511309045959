import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";
// import createPalette from 'material-ui/styles/createPalette';
// import createTypography from 'material-ui/styles/createTypography';
import PoppinsRegular from "typeface-poppins";
// import storage from 'redux-persist/lib/storage'
import storage from "localforage";
// import poppinsMedium from './assets/fonts/Poppins-Medium.ttf';
// import poppinsBold from './assets/fonts/Poppins-Bold.ttf';

import "./index.css";
import App from "./App";
import rootReducer from "./reducers/";
import themePalette from "./theme";
import { logout } from "./Views/Authenticantion/authActions";
import jwt from 'jsonwebtoken'

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { performRefreshToken } from "./utils/api";
import { showError } from "./utils/notifications";
import { getAuthToken } from "./utils/auth";
import { deleteCorrelation } from "./utils/helper";
const NoramlTheme = React.lazy(() => import("./NormalTheme"));
const MahindraTheme = React.lazy(() => import("./MahindraTheme"));
const defaultColorTheme = themePalette.theme.defalutTheme.primaryColor;
const customColorTheme = themePalette.theme.customTheme.primaryColor;
const mahindraColorTheme = themePalette.theme.MHDTheme.primaryColor;

let mainColor = defaultColorTheme;

if (process.env.REACT_APP_CUSTOM_THEME === "true") {
  mainColor = customColorTheme;
}
if(process.env.REACT_APP_PARTNER_NAME === "MHD"){
    mainColor = mahindraColorTheme;
}
const persistConfig = {
  blacklist: ["router", "snackbarReducer"],
  key: "cymmetri",
  storage,
  version: 0,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk));

const persistor = persistStore(store);

const poppins = {
  fontFamily: "Poppins",
  // fontDisplay: 'swap',
  src: `
      url(${PoppinsRegular}) format('ttf')
    `,
};

const theme = createMuiTheme({
  typography: {
    fontFamily: "poppins",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [poppins],
      },
    },
  },
  palette: {
    primary: {
      // main: '#363793',
      main: mainColor,
    },
  },
});



// (function () {
//   const originalFetch = fetch;
//   fetch = function () {

//     const token = jwt.decode(getAuthToken());
//     const now = parseInt(new Date().getTime() / 1000);
//     let isTokenValid = token ? now < token.exp : false;
//     if (localStorage.getItem('callRefreshToken') || !isTokenValid) {
//       setTimeout(() => console.log('Waiting for 2 sec'), 2000);
//     }

//     let arg = arguments;
//     // arg[1].headers.Authorization = arg[1].headers.Authorization ? `Bearer ${getAuthToken()}` : '';

//     if (arguments[1] && arguments[1].headers.Authorization) {
//       arguments[1].headers = {
//         Authorization: `Bearer ${getAuthToken()}`,
//         ...arguments[1].headers
//       }
//     }

//     return originalFetch.apply(this, arguments).then(function(data) {
//       if(data.status === 403) {
//         performRefreshToken();
//       }
//       return data;
//     });
//   };
// })();

window.addEventListener('storage', function (e) {
    if( e.key === 'logout' && e.oldValue===null && e.newValue ){
        store.dispatch(logout())
        deleteCorrelation()
        window.location="/#/auth/login"
    }
  },
  false
);


const ThemeSelect = ({ children }) => {
  const partnerName = process.env.REACT_APP_PARTNER_NAME || "CYM";
  return (
    <>
      <React.Suspense fallback={<></>}>
        {partnerName === "CYM" && <NoramlTheme />}
        {partnerName === "MHD" && <MahindraTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

const app = (
  <ThemeSelect>
    <ThemeProvider theme={theme}>

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider maxSnack={3}>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <App />
                    </MuiPickersUtilsProvider>
                </SnackbarProvider>
            </PersistGate>
        </Provider>

    </ThemeProvider>
  </ThemeSelect>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
